<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="avatar_src"
            :text="avatarText(title)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1"
          style="overflow: hidden;">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ title }}
              </h4>
              <span class="card-text">{{ subtitle || '' }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', ability)">
                <b-button
                  :to="{ name: link+'-edit', params: { id: id } }"
                  variant="primary"
                  v-if="showEdit"
                >
                  Edit
                </b-button>
              </template>

              <template v-if="$can('delete', ability)">
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                  v-if="showDelete"
                  @click="deleteModel"
                >
                  Delete
                </b-button>
              </template>

              <template v-if="$can('update', ability)">
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                  v-if="showRecover"
                  @click="recoverLicenceModel"
                >
                  Recover
                </b-button>
              </template>
              <b-button
                variant="outline-secondary"
                type="button"
                class="ml-1"

                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ name: link })"
              >
                {{ $t('forms.go_back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
      >
        <table class="mt-2 w-100">
          <tr v-for="item, idx in items" :key="idx">
            <th class="pb-50" style="width:200px;">
              <feather-icon
                :icon="item.icon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{item.text}}</span>
            </th>
            <td class="pb-50" style="max-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;"
            >
              {{item.value}}
            </td>
          </tr>
        
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    avatar_src: {
      type: [Number, String],
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    showRecover: {
      type: Boolean,
      required: false,
      default: true
    },
    items:{
      type: Array,
      required: true
    },
    ability: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    
  },
  mounted(){
    console.log(this.recordData, "dentro de info")
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    recoverLicenceModel(){
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to recover this school?', {
          title: 'Recover School',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'school_id': this.recordData.id
            }
            this.$http.post('school/recover', data).then( response =>{
              console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

              } else {
                console.log(response.data, "NO SE PUEDE RECUPERAR");
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
              }

            }).catch(error => {
              console.log("Err -> ", error)
            })
          }
        })
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this School?', {
          title: 'Delete school',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'school_id': this.recordData.id
            }
            this.$http.post('school/delete', data).then( response =>{
              console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                console.log(response.data, "NO SE PUEDE ELIMINAR");
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              console.log("Err -> ", error)
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  computed:{
    specialNeeds(){
      let arr = JSON.parse(this.recordData.special_needs_type)
      let sn_arr = []
      console.log(arr, "---------", this.special_needs)
      if(arr){
        arr.map(x => {
          this.special_needs.map(s => {
            if(s.id == x){
              sn_arr.push(s.name)
            }
          })
  
        })
      }
      return sn_arr
    },
    educationalStages(){
      let arr = JSON.parse(this.recordData.educational_stages)
      console.log(arr, "------EdST")
      let educ_st = []
      if(arr){
        arr.map(x => {
          switch(x){
            case '1':
              educ_st.push('Infantil')
              break;
            case '2':
              educ_st.push('Primaria')
              break;
            case '3':
              educ_st.push('Secundaria')
              break;
            case '4':
              educ_st.push('Otros Centros')
              break;
          }
        })
      }
      return educ_st
    }
  }
}
</script>

<style>

</style>
